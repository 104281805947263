<template>
  <v-container>
    <v-row justify="center" class="font-weight-light headline text-center">
      Continue apenas se você concordar com nossas políticas de privacidade
    </v-row>
    <v-row justify="center" class="pt-4">
      <v-col sm="12" md="4">
        <v-dialog width="550" v-model="dialogPoliticas">
          <template v-slot:activator="{ on }">
            <v-btn block outlined color="primary" v-on="on">
              <v-icon :color="politicasLidas ? 'green' : 'grey'"
                >mdi-checkbox-marked-circle</v-icon
              >
              <v-spacer></v-spacer>
              POLÍTICAS DE PRIVACIDADE
              <v-spacer></v-spacer>
            </v-btn>
          </template>
          <politicas-privacidade
            ref="PoliticasPrivacidade"
            @fechar="dialogPoliticas = false"
            @confirmar="confirmar()"
          ></politicas-privacidade>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const PoliticasPrivacidade = () => import("../PoliticasPrivacidade");

export default {
  components: {
    PoliticasPrivacidade
  },
  data() {
    return {
      dialogPoliticas: false,
      politicasLidas: false
    };
  },
  mounted() {
    this.$root.$on("reset_fields", () => {
      this.reset();
    });
  },
  methods: {
    confirmar() {
      this.politicasLidas = true;
      this.$emit("confirmar");
    },
    reset() {
      if (this.$refs.PoliticasPrivacidade) {
        this.$refs.PoliticasPrivacidade.reset();
      }
      this.dialogPoliticas = false;
      this.politicasLidas = false;
    }
  }
};
</script>
